import React from 'react'

function ContentDownload() {
    return (
        <div className="max-w-4xl m-auto md:pt-24 pt-12 px-8">
            <div>
                <h2 className="text-xl font-medium mb-5"> Mobile App</h2>
                <p className="font-normal text-base mb-5">Get the robust features and functions of your office
                phone system in the palm of your hand.  Complimentary to
                Vonage Business Communications customers.</p>
                <div className="mb-5">
                <a href="/" className="mb-5 underline">Download for iPhone</a>
                <br />
                <a href="/" className="mb-5 underline">Download for Android</a>
                </div>
            </div>
            <div>
                <h2 className="text-xl font-medium mb-5 ">Web/desktop app</h2>
                <p className="mb-5">Send calls, receive texts and control your Vonage Business
                     Communications phone system directly from your computer.
                     Complimentary to Vonage Business Communications customers.  </p>
                <div className="mb-5">
                    <a href="/" className="underline">Web app login</a><br />
                    <a href="/" className="underline">Desktop app download for Mac</a><br />
                    <a href="/" className="underline">Desktop app download for PC</a>
            </div>
            </div>
            <div>
                <h2 className="text-xl font-medium mb-5">Click-to-dial and more...</h2>
                <p className="mb-5">Initiate a call from any web page with just one click
                     with the Vonage Integration Platform. Add contact names,
                      notes, and view reports. Complimentary to Vonage Business
                       Communications customers. 
                </p>
                <div className="mb-5">
                <a href="/" className="underline">Firefox</a><br />
                <a href="/" className="underline">Chrome</a><br />
                <a href="/"className="underline">Microsoft Edge</a>
                </div>
            </div>
        </div>
    )
}

export default ContentDownload
