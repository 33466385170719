import React from "react";
import Index from "../views/Downloads/Index";
import Layout from "../components/Layout";

function Downloads({ location }) {
  return (
    <div>
      <Layout location={location}>
        <Index location={location} />
      </Layout>
    </div>
  );
}

export default Downloads;
