import React from "react";
import Header from "./Header";
import Speak from "./../../components/Speak";
import ContentDownload from "./ContentDownload";

function Index({ location }) {
  return (
    <div>
      <Header />
      <ContentDownload />
      <Speak location={location} />
    </div>
  );
}

export default Index;
