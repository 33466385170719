import React from "react";
import Headerp from "./../../images/Customer_App.svg";

function Header() {
  return (
    <div className="header-gradient">
      <div className="max-w-7xl m-auto md:pt-24 pt-12 px-8">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
          <div>
            <div className="mb-2">
              <span className="font-medium text-xs text-white">DOWNLOADS</span>
            </div>
            <h1 className=" font-medium text-white md:text-5xl text-4xl mb-4">
              Apps and downloads
            </h1>
            <p className="md:text-base text-sm font-normal text-white mb-5">
              Vonage Business Communications easily integrates with your mobile
              devices, web browsers, and business applications to help you be
              more efficient. Download and start using these apps and plugins
              today.
            </p>
          </div>
          <div className="justify-self-center">
            <img src={Headerp} alt="" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
